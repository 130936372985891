<template>
	<div class="container-fluid p-0">
		<div class="row mb-2 mb-xl-3">
			<div class="col-auto d-none d-sm-block">
				<h3>Manage API Tokens</h3>
			</div>
		</div>
		<!-- <div class="row" v-if="accessToken != null">
			<div class="col-md-12">
				<div class="alert alert-success">Your new API token has been created.</div>
			</div>
		</div> -->
		<div class="row" v-if="error != null">
			<div class="col-md-12">
				<div class="alert alert-danger">{{ error }}</div>
			</div>
		</div>
		<div class="row row-cols-md-auto align-items-center" v-if="accessToken != null">
			<div class="col-md-12">
				<label>Your new API token</label>
			</div>
			<div class="col-6">
				<input type="text" class="form-control" v-model="accessToken" readonly>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-info btn-sm" v-clipboard:copy="accessToken"><font-awesome-icon icon="clipboard" /> Copy</button>
			</div>
			<div class="col-md-12">
				<small id="emailHelp" class="form-text text-muted">Make sure you save it - you won't be able to access it again. Please refer to the API documentation for instructions on how to use it. Do not share it with others.</small>
			</div>
			<div class="col-md-12">
				<br>
			</div>
		</div>
<!-- 		<div class="row" v-if="accessToken != null">
			<div class="col-md-12">
				<label>Your new API token</label>
			</div>
			<div class="col-md-6">
				<input type="text" class="form-control" v-model="accessToken" readonly>
			</div>
			<div class="col-md-6">
				<button type="button" class="btn btn-info btn-" v-clipboard:copy="accessToken"><font-awesome-icon icon="clipboard" /> Copy</button>
			</div>
			<div class="col-md-12">
				<small id="emailHelp" class="form-text text-muted">Make sure you save it - you won't be able to access it again. Do not share it with others.</small>
			</div>
			<div class="col-md-12">
				<br>
			</div>
		</div> -->
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title mb-0">Add API Token</h5>
					</div>
					<div class="card-body">
						<div class="row row-cols-md-auto align-items-center">
							<div class="col-6">
								<label class="sr-only" for="inlineFormInputName2">Name</label>
								<input type="text" class="form-control" id="inlineFormInputName2" placeholder="Token Name" v-model="tokenName">
							</div>
							<div class="col-6">
								<button class="btn btn-success btn-sm" v-if="issuing" disabled><font-awesome-icon icon="spinner" spin /> Adding</button>
								<button class="btn btn-success btn-sm" @click="issueToken" v-else><font-awesome-icon icon="plus-circle" /> Add API Token</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-12 d-flex">
				<div class="card flex-fill">
					<div class="card-header">
						<h5 class="card-title mb-0">Active API Tokens</h5>
					</div>
					<table class="table table-sm table-striped my-0" v-if="tokens.length">
						<thead>
							<tr>
								<th class="d-none d-xl-table-cell">Name</th>
								<th class="d-none d-xl-table-cell">Created Date</th>
								<th class="d-none d-xl-table-cell">Expiry Date</th>
								<th class="d-none d-xl-table-cell">Actions</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(token, index) in tokens" v-bind:key="token.id">
								<td class="d-none d-xl-table-cell">{{ token.name }}</td>
								<td class="d-none d-xl-table-cell">{{ token.created_at }}</td>
								<td class="d-none d-xl-table-cell">{{ token.expires_at }}</td>
								<td class="d-none d-xl-table-cell">
									<button class="btn btn-danger btn-sm" v-if="revoking" disabled><font-awesome-icon icon="spinner" spin /> Revoking</button>
									<button class="btn btn-danger btn-sm" v-else @click="revokeToken(token.id, index)"><font-awesome-icon icon="trash" /> Revoke</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="card-body" v-else>
						<div class="alert alert-info">You have no active API tokens.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "TokenIndex",
		mounted() {
			// this.$toast.success('f');
			this.$axios.get(`${process.env.VUE_APP_API_URL}/v1/user/token`)
			.then(response => {
				this.tokens = response.data.data;
			})
			.catch(error => {
				console.log(error);
			})
		},
		data()
		{
			return {
				tokens:[],
				tokenName:null,
				accessToken:null,
				error:null,
				issuing:false,
				revoking:false,
			}
		},
		methods:{
			issueToken()
			{
				if(this.issuing)return;
				this.issuing = true;
				this.$axios.post(`${process.env.VUE_APP_API_URL}/v1/user/token`, {name: this.tokenName})
				.then(response => {
					const data = response.data.data;
					this.tokens.unshift(data.token);
					this.accessToken = data.accessToken;
					this.error = null;
					this.$toast.success('Token Generated.')
				})
				.catch(error => {
					console.log(error);
					// this.error = error.response.data.error;
					this.accessToken = null;
					this.$toast.error(error.response.data.message);
				})
				.finally(() => this.issuing = false)
			},
			revokeToken(token, index)
			{
				if(confirm("Are you sure you want to delete this token?"))
				{
					if(this.revoking)return;
					this.revoking = true;
					this.$axios.delete(`${process.env.VUE_APP_API_URL}/v1/user/token/${token}`)
					.then(() => {
						this.tokens.splice(index, 1);
						this.$toast.success('Token Revoked.')
					})
					.catch(error => {
						console.log(error);
						this.$toast.error(error.response.data.message);
					})
					.finally(() => this.revoking = false)
				}
			},
		}
	}
</script>
